<template>
  <div class="tw-flex tw-flex-row tw-space-x-2">
    <k-button
      icon
      color="regular"
      @click="$emit('move-down')"
      :title="$t('profile.actions.move_down')"
      :disabled="moveDownDisabled"
      rounded
    >
      <k-icon name="chevron_down" size="24" />
    </k-button>

    <k-button
      icon
      color="regular"
      @click="$emit('move-up')"
      :title="$t('profile.actions.move_up')"
      :disabled="moveUpDisabled"
      rounded
    >
      <k-icon name="chevron_up" size="24" />
    </k-button>

    <k-button
      @click="$emit('remove')"
      icon
      color="red-outlined"
      rounded
    >
      <k-icon name="trash" size="14" />
    </k-button>
  </div>
</template>

<script>
export default {
  props: ['moveUpDisabled', 'moveDownDisabled']
}
</script>
