<template>
  <div class="tw-bg-transparent tw-space-y-3">
    <template v-if="hasProfile">
      <div v-if="!hasGdprAccepted" class="tw-mb-4">
        <k-gdpr
          v-model="gdpr"
          :showNewsletter="false"
          :invalid="submitted && (!gdpr || !gdpr.gdprValue)"
          :gdprTooltip="$t('gdpr.descriptions.privacy_policy_tooltip')"
        />
      </div>

      <div class="tw-flex tw-items-center">
        <k-button color="primary" class="tw-w-full" size="lg" @click="submit" :loading="loading" :success="success">
          <k-icon name="save" :size="18" class="tw-mr-1" />
          {{ $t('global.actions.save') }}
        </k-button>
      </div>

      <div class="tw-grid tw-grid-cols-2 tw-gap-x-3">
        <k-button
          @click="$emit('save-pdf')"
          :loading="isGeneratingCv"
          color="secondary"
          :disabled="isSentBtnBlocked || isGeneratingCv"
        >
          <k-icon name="pdf" :size="18" class="tw-mr-1" />
          {{ $t('profile.actions.send_cv_by_email') }}
        </k-button>

        <k-button :href="`${localePrefix}/cv/${profile.id}`" target="_blank" color="secondary">
          <k-icon name="link_outside" :size="18" class="tw-mr-1" />
          {{ $t('profile.actions.show_resume') }}
        </k-button>
      </div>

      <v-expand-transition>
        <div v-show="isGeneratingCv" class="tw-mt-1 tw-text-gray-500 tw-text-sm tw-leading-relaxed">
          <p v-html="$t('profile.descriptions.cv_generation_in_progress', { email: userEmail })" />
        </div>
      </v-expand-transition>
      <!--
      <div class="tw-w-full tw-text-center tw-mt-4">
          <p>
              {{ $t('profile.import_your_data_from') }}
              <a
                  @click="$emit('linkedin-import')"
                  class="tw-font-bold tw-underline hover:tw-text-koderia tw-transition-colors tw-duration-75 tw-ease-in"
              >
                  {{ $t('profile.linkedin') }}.
              </a>
          </p>
      </div>
      -->
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    isGeneratingCv: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      gdpr: undefined,
      submitted: false
    }
  },
  computed: {
    ...mapGetters({
      hasProfile: 'USER/getHasUserProfile',
      profile: 'USER/getUserProfile',
      cvSettings: 'USER/getCvSettings',
      hasGdprAccepted: 'USER/getHasUserGdprAccepted',
      userEmail: 'AUTH/getAuthUserEmail'
    }),
    localePrefix() {
      const { language } = this.cvSettings

      return language ? `/${ language }` : ''
    },
    isSentBtnBlocked() {
      return this.cvSettings?.audience?.value === 'TOKEN' && !this.cvSettings.token
    }
  },
  methods: {
    submit() {
      this.submitted = true

      if (this.hasGdprAccepted || this.gdpr?.gdprValue) {
        this.$emit('submit')
      }
    }
  }
}
</script>

<style></style>
