import { render, staticRenderFns } from "./ka-user-cv-linkedin-modal.vue?vue&type=template&id=084d4bcc&scoped=true&"
import script from "./ka-user-cv-linkedin-modal.vue?vue&type=script&lang=js&"
export * from "./ka-user-cv-linkedin-modal.vue?vue&type=script&lang=js&"
import style0 from "./ka-user-cv-linkedin-modal.vue?vue&type=style&index=0&id=084d4bcc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084d4bcc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KIcon from '@web/components/core/k-icon'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {KButton,KIcon,VBtn,VCard,VCol,VDialog,VRow})
