<template>
  <div class="wrapper">
    <div class="head">
      <span class="tw-text-sm tw-leading-7 tw-text-gray-800 tw-font-semibold">{{ $t('profile.technologies') }}</span>
      <span class="tw-text-sm tw-leading-7 tw-text-gray-800 tw-font-semibold">
        {{ $t('profile.years_of_experience') }}
      </span>
      <span style="width: 44px"></span>
    </div>

    <div class="form-item-grid" v-for="(expertise, idx) in value" :key="expertise.expertiseId">
      <k-autocomplete-technologies
        :object-value="expertise"
        :multiple="false"
        :placeholder="$t('profile.inputs.technology_software_activity')"
        :selected-expertises-ids="selectedExpertisesIds"
        @input="handleExpertiseSelect($event, idx)"
        class="tw-w-full"
        style="grid-area: tech;"
      />

      <k-select
        v-model="expertise.yearsOfExperience"
        :items="allExperiences"
        :placeholder="$t('profile.inputs.numbers_of_years_of_experience')"
        class="tw-w-full"
        style="grid-area: prax;"
      />

      <k-button
        v-if="idx < value.length - 1"
        @click="remove(idx)"
        color="red-outlined"
        icon
        style="grid-area: remove;"
        rounded
      >
        <k-icon name="trash" size="14" />
      </k-button>
      <k-button
        v-else
        icon
        style="grid-area: remove;"
        class="tw-invisible"
      >
        <k-icon name="trash" size="14" />
      </k-button>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import KAutocompleteTechnologies from '@web/components/core/k-autocomplete-technologies.vue'

export default {
  components: { KAutocompleteTechnologies },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    selectedExpertisesIds: {
      type: Array,
      default: () => []
    },
    moveUpDisabled: {
      type: Boolean,
      default: false
    },
    moveDownDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newValue: {
        name: null,
        experience: null
      }
    }
  },
  computed: {
    allExperiences() {
      return [
        { value: '0-1', text: this.$t('global.expertiseLevel.0-1') },
        { value: '1', text: this.$t('global.expertiseLevel.1') },
        { value: '2', text: this.$tc('global.expertiseLevel.2') },
        { value: '3', text: this.$tc('global.expertiseLevel.3') },
        { value: '4', text: this.$tc('global.expertiseLevel.4') },
        { value: '5-10', text: this.$t('global.expertiseLevel.5-10') },
        { value: '10+', text: this.$t('global.expertiseLevel.10+') }
      ]
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(value) {
        if (!value || value.length < 1) {
          this.$emit(
            'add',
            {
              expertiseId: null,
              name: null,
              type: null,
              yearsOfExperience: null
            }
          )

          return
        }

        const last = value && value.length > 0 ? value[value.length - 1] : {}

        if (last.name) {
          this.$emit(
            'add',
            {
              expertiseId: null,
              name: null,
              type: null,
              yearsOfExperience: null
            }
          )
        }
      }
    }
  },
  methods: {
    remove(i) {
      let items = [...this.value]
      items = items.slice(0, i).concat(items.slice(i + 1, items.length))
      this.$emit('input', items)
    },
    handleExpertiseSelect(expertise, idx) {
      const copy = cloneDeep(this.value)
      copy[idx] = { ...copy[idx], ...expertise }

      this.$emit('input', copy)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.wrapper {
  min-height: 311px;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  .head {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    column-gap: 1.5rem;
    margin-bottom: -0.5rem;
  }

  @media screen and (max-width: 576px) {
    .form-item-grid:not(:last-child) {
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $koderia-gray-200;
    }
  }
}

.form-item-grid {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-areas:
        "tech remove"
        "prax remove";
  grid-template-columns: 1fr 3.5rem;
  grid-template-rows: 1fr 1fr;
  gap: 1.5rem;

  @include md {
    grid-template-areas: "tech prax remove";
    grid-template-columns: 1fr 1fr auto;
    grid-template-rows: 1fr;
    gap: 1.5rem;
  }

  @media screen and (max-width: 567px) {
    gap: 0.5rem;
  }
}
</style>
