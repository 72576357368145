<template>
  <div>
    <div class="grid">
      <slot name="avatar">
        <div class="tw-bg-gray-100 tw-w-12 tw-h-12 tw-rounded-full tw-flex tw-items-center tw-justify-center">
          <slot name="icon" />
        </div>
      </slot>

      <slot name="title" />
    </div>

    <div class="grid">
      <div />

      <p class="tw-text-gray-500 tw-text-sm tw-leading-relaxed">
        <slot name="subtitle" />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'section-header'
}
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 3rem auto;
  gap: 1rem;
  align-items: center;
}
</style>
